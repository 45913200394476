import {ValueObject} from "../../model/ValueObject";
import {NocoDbProjectProxy} from "../NocoDbProjectProxy";
import {IListingReponse} from "../ListingReponse";
import {NocoClusterId} from "./NocoCluster";
import {ILogger} from "../../log/Logger";
import {LoggerFactory} from "../../log/LoggerFactory";
import {NocoProductId} from "./NocoProduct";

export interface INocoClusterHierarchy {
  Parent: NocoClusterId;
  Child: NocoClusterId;
  ProductId?: NocoProductId;
  Core: number;
  Starter: number;
  Sequence?: number;
  Optional?: number;
}


export class NocoClusterHierarchy extends ValueObject<INocoClusterHierarchy> {

  protected onSetValue(value: INocoClusterHierarchy | null) {
  }

  constructor( value: INocoClusterHierarchy | null ) {
    super(value);
    if (value) {
      this.value = value;
    }
  }
}

export class NocoClusterHierarchySet {

  private static readonly tableName: string = 'cluster_hierarchy';

  private _log: ILogger = LoggerFactory.build( 'NocoClusterHierarchySet' );

  values: NocoClusterHierarchy[] = [];
  valuesById: {[id: number]: NocoClusterHierarchy} = {};

  public getRoot(productId : NocoProductId): NocoClusterHierarchy {
    const root = this.values.find(h =>
      (h.value.Parent === h.value.Child &&
      h.value.ProductId === productId));

    if (!root) {
      this._log.error("Unable to find cluster root", "productId", productId);
    }

    return root;
  }

  public getRoots(): NocoClusterHierarchy[] {
    const roots: NocoClusterHierarchy[] = [];

    for(const candidate of this.values) {
      if(candidate.value.Child === candidate.value.Parent) {
        roots.push(candidate);
      }
    }

    this._log.info("Roots", roots);

    return roots;
  }

  public getHierarchy( parentId: NocoClusterId, childId: NocoClusterId ): NocoClusterHierarchy|null {
    for( const candidate of this.values ) {
      if( candidate.value.Parent === parentId && candidate.value.Child === childId ) {
        return candidate;
      }
    }
    return null;
  }

  public static async getValue( proxy: NocoDbProjectProxy ): Promise<IListingReponse<INocoClusterHierarchy>> {
    return proxy.getView<INocoClusterHierarchy>(this.tableName);
  }

  public static async build(proxy: NocoDbProjectProxy): Promise<NocoClusterHierarchySet> {
    const value: IListingReponse<INocoClusterHierarchy> = await proxy.getView<INocoClusterHierarchy>(this.tableName);
    return new NocoClusterHierarchySet(value);
  }

  public constructor( public value: IListingReponse<INocoClusterHierarchy> ) {
    for( const rowValue of value.list ) {
      const reference = new NocoClusterHierarchy( rowValue );
      this.values.push( reference );
      const key = rowValue.Parent + ":" + rowValue.Child;
      this.valuesById[key] = reference;
    }
  }
}

