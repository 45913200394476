
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {SelectPropertyPage} from "../page.select-property/select-property";
import {CompletedPage} from "../page.completed/completed";
import {DocumentsComponent} from "../page.documents/documents.component";
import {PasswordResetPage} from "../page.password-reset/password-reset.page";
import {PageAuditComponent} from "../page.audit/page-audit.component";
import {PageClusterPageComponent} from "../page.cluster-page/page-cluster-page.component";
import {PageHomeComponent} from "../page.home/page-home.component";

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
    component: PageHomeComponent,
  },
  {
    path: 'back-office',
    loadChildren: () => import('../module.back-office/back-office.module').then(mod => mod.BackOfficeModule)
  },
  {
    path: 'cluster-page/:clusterId/:activeIndex/:sectionIndex/:subSectionIndex',
    component: PageClusterPageComponent,
  },
  {
    path: 'documents',
    component: DocumentsComponent,
  },
  {
    path: 'home',
    component: PageHomeComponent,
    pathMatch: 'full',
  },
  {
    path: 'home:params',
    component: PageHomeComponent,
    pathMatch: 'full',
  },
  {
    path: 'for/:propertyKey',
    component: PageAuditComponent,
  },
  {
    path: 'audit',
    component: PageAuditComponent,
    pathMatch: 'full',
  },
  {
    path: 'audit/:propertyKey',
    component: PageAuditComponent,
  },
  {
    path: 'password-reset/:emailAddress',
    component: PasswordResetPage,
  },
  {
    path: 'select-property',
    component: SelectPropertyPage,
  },
  {
    path: 'score',
    component: CompletedPage
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    paramsInheritanceStrategy: 'always',
    useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
