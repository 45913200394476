import {IFirebaseConnection} from "../../FirebaseConnection";
import {AppAudit, IAppAudit} from "../../../model/AppAudit";

export class FirebaseAudit {

  private static getPath(clientKey:string, propertyId: string) {
    return `/${clientKey}/audits/${propertyId}`;
  }

  static async readAll(firebaseConnection: IFirebaseConnection, clientKey: string): Promise<AppAudit[]> {

    const values: { [clientKey: string]: IAppAudit; } = await firebaseConnection.database2.getValue(`${clientKey}/audits`);
    const appAudits: AppAudit[] = [];

    for(const propertyKey of Object.keys(values)) {
      appAudits.push(new AppAudit(values[propertyKey], propertyKey));
    }

    return appAudits;
  }

  static async readReference(firebase: IFirebaseConnection, clientKey: string, propertyKey: string): Promise<AppAudit|null> {
    const path = this.getPath(clientKey, propertyKey);
    const value: IAppAudit = await firebase.database2.getValue<IAppAudit>(path);

    if (value) {
      return new AppAudit(value, propertyKey);
    }

    return null;
  }

  static async writeReference(firebaseConnection: IFirebaseConnection, clientKey: string, audit: AppAudit): Promise<void> {
    return FirebaseAudit.writeValue(firebaseConnection, clientKey, audit.value);
  }

  static async writeValue(firebaseConnection: IFirebaseConnection, clientKey: string, value: IAppAudit): Promise<void> {
    const path = this.getPath(clientKey, value.propertyKey);
    return firebaseConnection.database2.setValue(path, value);
  }
}
