import {ILogger} from "../javascript.lib.mojo-base/log/Logger";
import {LoggerFactory} from "../javascript.lib.mojo-base/log/LoggerFactory";
import {AppClusterType} from "../javascript.lib.mojo-base/model/app.cluster/AppClusterType";
import {AppCluster} from "../javascript.lib.mojo-base/model/app.cluster/AppCluster";
import {NocoDbProduct} from "../javascript.lib.mojo-base/nocodb/NocoDbProduct";
import {FirebaseCluster} from "../javascript.lib.mojo-base/firebase/realtime-database/answer-clusters/FirebaseCluster";
import {AppParentChild} from "../javascript.lib.mojo-base/model/app.cluster/AppParentChild";
import {FirebaseParentChild} from "../javascript.lib.mojo-base/firebase/realtime-database/answer-clusters/FirebaseParentChild";
import {AppProperty} from "../javascript.lib.mojo-base/model/AppProperty";
import {FirebaseConnectionService} from "../common/service.firebase-connection/FirebaseConnectionService";
import {SessionContextProvider} from "../service.session-context/session-context-provider";
import {PropertyService} from "../service.property/property-service";
import {AppParentChildSet} from "../javascript.lib.mojo-base/model/app.cluster/AppParentChildSet";
import {AppClusterSet} from "../javascript.lib.mojo-base/model/app.cluster/AppClusterSet";
import {root} from "rxjs/internal-compatibility";
import {child} from "@angular/fire/database";

export class HierarchyBuilder {

  private _log: ILogger = LoggerFactory.build('HierarchyBuilder');

  constructor(public property: AppProperty,
              public firebase: FirebaseConnectionService,
              public sessionContext: SessionContextProvider,
              public propertyService: PropertyService) {
  }

  public async getOrBuild(rootClusterType: AppClusterType): Promise<AppCluster> {
    if (!this.property) {
      this._log.warn("getOrBuild: No Property");
      return;
    }

    const product = NocoDbProduct.INSTANCE;
    const existingClusters = await FirebaseCluster.readReferences(this.firebase, this.sessionContext.clientKey,
      this.property.propertyKey, this.property.value.productType);
    const existingRelationships = await FirebaseParentChild.readReferences(
      this.firebase, this.sessionContext.clientKey, this.property.propertyKey, this.propertyService.productType);

    let rootCluster : AppCluster = await this._getOrBuildRootCluster(rootClusterType, existingClusters);

    await this._getOrBuildApplicableChildren(product, rootCluster, existingRelationships, existingClusters, rootClusterType, 1);

    return rootCluster;
  }

  private async _getOrBuildRootCluster(clusterType: AppClusterType,
                                       existingClusters: AppClusterSet) : Promise<AppCluster> {
    let rootCluster = existingClusters.findClusterByType(clusterType);
    if (rootCluster) {
      return rootCluster;
    }

    rootCluster = clusterType.buildAppCluster();
    rootCluster.value.core = true;
    rootCluster.value.name = this.property.value.name;

    await FirebaseCluster.writeReference(this.firebase, this.sessionContext.clientKey, this.property.propertyKey, this.propertyService.productType, rootCluster);

    return rootCluster;
  }

  private async _getOrBuildApplicableChildren(product: NocoDbProduct,
                                              parent: AppCluster,
                                              existingRelationships: AppParentChildSet,
                                              existingClusters: AppClusterSet,
                                              parentClusterType: AppClusterType,
                                              depth: number) {
    if(depth === 3) {
      return;
    }

    const childrenTypes: AppClusterType[] = product.getClusterTypeChildren(parentClusterType, true);

    if (!childrenTypes) {
      return;
    }

    for(const childType of childrenTypes) {
      const child = await this._getOrBuildCluster(product, parent, existingRelationships, existingClusters, parentClusterType, childType);
      await this._getOrBuildApplicableChildren(product, child, existingRelationships, existingClusters, childType, depth + 1);
    }
  }

  private async _getOrBuildCluster(product: NocoDbProduct,
                                   parent: AppCluster,
                                   existingRelationships: AppParentChildSet,
                                   existingClusters: AppClusterSet,
                                   parentClusterType: AppClusterType,
                                   childType: AppClusterType) : Promise<AppCluster> {
    if (!childType) {
      return
    }

    let child: AppCluster = null;
    const childClusterTypeId = childType.value.clusterId;
    const candidateChildren = existingClusters.values.filter((c) => c.value.clusterTypeId == childClusterTypeId)

    let existingChildrenReferences = existingRelationships.values.filter((r) => r.parent.value.id == parent._self.value.id);
    for (let candidateChild of candidateChildren) {
      let match = existingChildrenReferences.find((r) => r.child.value.id == candidateChild._self.value.id);
      if (match){
        child = candidateChild;
        break;
      }
    }

    if (child) {
      return child;
    }

    let childCluster = childType.buildAppCluster();

    await FirebaseCluster.writeReference(this.firebase, this.sessionContext.clientKey, this.property.propertyKey, this.propertyService.productType, childCluster);
    await this._createRelationship(product, existingRelationships, parent, parentClusterType, childType, childCluster);

    return childCluster;
  }


  private async _createRelationship(product: NocoDbProduct,
                                    existingRelationships: AppParentChildSet,
                                    parent: AppCluster,
                                    parentClusterType: AppClusterType,
                                    childType: AppClusterType,
                                    child: AppCluster) : Promise<void> {
    if (!parent || !child) {
      return;
    }

    const hierarchy = product.clusterHierarchies.getHierarchy(parentClusterType.nocoCluster.value.Id, childType.nocoCluster.value.Id);

    const core = 1 === hierarchy.value.Core;
    const optional = 1 === hierarchy.value.Optional;
    if (child.value.core != core || child.value.optional != optional) {
      child.value.core = core;
      child.value.optional = optional;
      await FirebaseCluster.writeReference(this.firebase, this.sessionContext.clientKey, this.property.propertyKey, this.propertyService.productType, child);
    }

    let existingParentChildRelationship = existingRelationships.getParentChild(parent._self, child._self);
    if (!existingParentChildRelationship)  {
      const parentChild = AppParentChild.buildNew(parent, child, hierarchy.value.Sequence);
      await FirebaseParentChild.writeReference(this.firebase, this.sessionContext.clientKey, this.property.propertyKey, this.propertyService.productType, parentChild);
    }
  }
}
