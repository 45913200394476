<div cclass="app-content">

  <div *ngIf="bannerText" class="corner-ribbon top-right sticky">{{bannerText}}</div>

  <div cclass="router_wrapper">
    <router-outlet >
    </router-outlet>
  </div>

</div>


