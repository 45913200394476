// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

import {IProductEnvironment} from "./FacilitiesEvaluationEnvironment";
import {EProductType} from "../javascript.lib.mojo-base/model/ProductType";

export const environment: IProductEnvironment = {

  name: 'Unity UAT',
  filename: 'environment.unity-uat.ts',
  defaultClientKey: '77778484',

  isDevelopment: false,
  isTest: false,
  isProduction: true,
  environmentBanner: "UAT",

  authenticationConfig: {
    emailLink: false,
    textMessage: false,
    usernamePassword: true,
    google: true
  },

  firebaseConfig: {
    apiKey: "AIzaSyCCSmHa0zWEZQ85MLevBHY_MTQbAey8glw",
    authDomain: "mm-banking.firebaseapp.com",
    databaseURL: "https://mm-banking-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "mm-banking",
    storageBucket: "mm-banking.appspot.com",
    messagingSenderId: "147804987950",
    appId: "1:147804987950:web:3a9a35d124e4752349f5bf",
    measurementId: "G-1WLFQMDKXP"
  },

  cloudFunctionsUrl: 'https://us-central1-mm-banking.cloudfunctions.net',

  nocoDbConfig: {
    httpServer: 'https://next.mobilitymojo-spot.com',
    user: 'api-viewer@mobilitymojo.com',
    password: 'IxqWxN;A<s2b=(H',
    authorization: 'Basic bW9iaWxpdHk6bW9qbw==',
    projectTitle: 'UAT-Unity',
    projectId: 'pngmf75e1itbhex',
  },

  productConfig: {
    firebaseAnswersRoot: '/answers-x/banking',
    productType: EProductType.banking_2024,
    canCommentOnClusters: true,
    canEditClusters: false,
  },

  preview: {
    fireworksEnabled: true,
  },
};
