import {Component, OnInit} from "@angular/core";
import {MatButtonModule} from "@angular/material/button";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatToolbarModule} from "@angular/material/toolbar";
import {NgIf} from "@angular/common";
import {ActivatedRoute, Router, RouterLinkWithHref} from "@angular/router";
import {SessionCheckerComponent} from "../common/component.session-checker/session-checker.component";
import {SideMenuComponent} from "../common/module.base-components/component.side-menu/side-menu.component";
import {ILogger} from "../javascript.lib.mojo-base/log/Logger";
import {LoggerFactory} from "../javascript.lib.mojo-base/log/LoggerFactory";
import {SessionContextProvider} from "../service.session-context/session-context-provider";
import {SessionContextState} from "../common/service.session-context/BaseSessionContext";
import {BackOfficeRouteManifest} from "../module.back-office/BackOfficeRouteManifest";
import {Subscriptions2} from "../javascript.lib.mojo-base/util/Subscriptions2";
import {PropertyService} from "../service.property/property-service";
import {AppRouteManifest} from "../app/AppRouteManifest";
import {AppStorage} from "../app/AppStorage";

@Component({
  selector: 'page-home',
  styleUrls: ['page-home.component.scss'],
  templateUrl: './page-home.component.html',
  standalone: true,
  imports: [
    MatButtonModule,
    MatSidenavModule,
    MatToolbarModule,
    NgIf,
    RouterLinkWithHref,
    SessionCheckerComponent,
    SideMenuComponent
  ]
})
export class PageHomeComponent implements OnInit  {

  private _log: ILogger = LoggerFactory.build( 'PageHomeComponent' );
  private _subscriptions: Subscriptions2 = new Subscriptions2();

  constructor(public router: Router,
              public route: ActivatedRoute,
              public sessionContext: SessionContextProvider,
              public propertyService: PropertyService) {
  }

  private async onParam() {
    let propertyKey = await AppRouteManifest.AUDIT.getPropertyKey(this.route);
    this._subscriptions.subscribe(this.sessionContext.stateSubject, (state : SessionContextState) => {
      if (state === SessionContextState.UserIsReady) {
          if (this.sessionContext.isAdministrator && !propertyKey) {
            BackOfficeRouteManifest.Home.navigate(this.router);
            return;
          }
          if (!propertyKey) {
            propertyKey = AppStorage.getPropertyKey(this.sessionContext.user.userUid);
          }
          if (propertyKey) {
            AppRouteManifest.AUDIT.navigate(this.router, {"propertyKey": propertyKey});
          } else {
            AppRouteManifest.SELECT_PROPERTY.navigate(this.router);
          }
      }
    });
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(  (params) => {
      return this.onParam();
    });
  }
}
