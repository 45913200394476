import {AppCluster, IAppCluster} from "./AppCluster";
import {AppTypedReference, AppTypedReferenceString} from "../cg/core/AppTypedReference";
import {AppClusterType} from "./AppClusterType";
import {ILogger} from "../../log/Logger";
import {LoggerFactory} from "../../log/LoggerFactory";

export class AppClusterSet {

  private static _log: ILogger = LoggerFactory.build('AppClusterSet');

  values: AppCluster[] = [];
  valuesById: {[id: AppTypedReferenceString]: AppCluster} = {};

  public add(cluster: AppCluster) {
    this.values.push(cluster);
    this.valuesById[cluster._self.toString] = cluster;
  }


  public getSubset( include: AppTypedReference[] ): AppClusterSet {

    const answer = new AppClusterSet( {} );
    for( const reference of include ) {
      const cluster = this.valuesById[reference.toString];
      answer.values.push( cluster );
      answer.valuesById[reference.toString] = cluster;
    }

    return answer;
  }

  public findClusterByType(clusterType: AppClusterType): AppCluster|null {
    try {
      return this.values.find((c) =>
        c.value.clusterTypeId === clusterType.value.clusterId);
    } catch (e) {
      AppClusterSet._log.error("Can't find clusterType", clusterType);
      throw e;
    }
  }

  constructor( value: { [key: AppTypedReferenceString]: IAppCluster; } ) {
    for( const key of Object.keys( value )) {
      const objectValue:IAppCluster = value[key];
      const cluster = new AppCluster(objectValue);
      this.values.push(cluster);
      this.valuesById[key] = cluster;
    }
  }
}
