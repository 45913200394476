import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AppCluster} from "../../javascript.lib.mojo-base/model/app.cluster/AppCluster";
import {AppClusterNode} from "../../javascript.lib.mojo-base/model/app.cluster/AppClusterNode";
import {ILogger} from "../../javascript.lib.mojo-base/log/Logger";
import {LoggerFactory} from "../../javascript.lib.mojo-base/log/LoggerFactory";
import {
  ClusterEditDialogComponent,
  IClusterEditDialogParams,
  IClusterEditDialogResponse
} from "./component.cluster-edit-dialog/cluster-edit-dialog";
import {NocoDbProduct} from "../../javascript.lib.mojo-base/nocodb/NocoDbProduct";
import {AppRouteManifest} from "../../app/AppRouteManifest";
import {AppClusterType} from "../../javascript.lib.mojo-base/model/app.cluster/AppClusterType";
import {MatDialog} from "@angular/material/dialog";
import {
  FirebaseCluster
} from "../../javascript.lib.mojo-base/firebase/realtime-database/answer-clusters/FirebaseCluster";
import {SessionContextProvider} from "../../service.session-context/session-context-provider";
import {FirebaseConnectionService} from "../../common/service.firebase-connection/FirebaseConnectionService";
import {Router} from "@angular/router";
import {EEvaluationState} from "../EEvaluationState";
import {environment} from "../../environments/environment";
import {
  ClusterCommentDialogComponent,
  IClusterCommentDialogParams,
  IClusterCommentDialogResponse
} from "./component.cluster-comment/cluster-comment-dialog";
import {AppQuestion} from "../../javascript.lib.mojo-base/model/AppQuestion";
import {
  AppClusterAnswers,
  IAppClusterAnswers
} from "../../javascript.lib.mojo-base/model/app.cluster/AppClusterAnswers";
import {
  FirebaseClusterAnswers
} from "../../javascript.lib.mojo-base/firebase/realtime-database/answer-clusters/FirebaseClusterAnswers";
import {AppQuestionSet} from "../../javascript.lib.mojo-base/model/AppQuestionSet";
import {EProductType} from "../../javascript.lib.mojo-base/model/ProductType";
import {PropertyService} from "../../service.property/property-service";
import {EAppAuditState} from "../../javascript.lib.mojo-base/model/AppAudit";

@Component({
  selector: 'app-cluster',
  styleUrls: ['cluster.component.scss'],
  templateUrl: './cluster.component.html'
})

export class ClusterComponent implements OnInit {

  private _log: ILogger = LoggerFactory.build( 'ClusterComponent' );
  private _commentQuestion: AppQuestion|null = null;

  public canEditClusters = environment.productConfig.canEditClusters;
  public clusterQuestions: AppQuestionSet|null = null;
  public hasAnyOptionalChildren: boolean = false;

  @Input() productType: EProductType;
  @Input() sampleInput: string = "some sample input";
  @Input() childCluster: AppCluster;
  @Input() clusterNode: AppClusterNode;
  @Input() sectionIndex: number = 1;
  @Input() state: EAppAuditState = EAppAuditState.unknown;

  @Output() deleteCluster = new EventEmitter<AppClusterNode>();
  @Output() addCluster = new EventEmitter<AppClusterNode>();
  @Output() includeCluster = new EventEmitter<AppClusterNode>();

  async onComment( clusterNode: AppClusterNode ) {

    this._log.debug( 'onChildClick', 'clusterNode', clusterNode );

    const propertyKey: string = this.propertyService.propertyContext.propertyKey;
    const productType: EProductType = this.propertyService.productType;

    // get the answers ...
    let clusterAnswers: AppClusterAnswers = null;
    {

      const clusterAnswersValue: IAppClusterAnswers = await FirebaseClusterAnswers.readValue( this.firebase, this.sessionContext.clientKey, propertyKey, productType, clusterNode.cluster );
      if( clusterAnswersValue ) {

        clusterAnswers = new AppClusterAnswers( clusterAnswersValue );
      } else { // first time answering these questions

        clusterAnswers = AppClusterAnswers.build( clusterNode.cluster._self.value.id );
      }
    }

    const answerSet = clusterAnswers.toAnswerSet(this.clusterQuestions);
    const comment = answerSet.getAnswer(this._commentQuestion);

    const params: IClusterCommentDialogParams = {
      clusterName: clusterNode.cluster.value.name,
      comment
    };

    this._log.debug('params', params);

    const dialog = ClusterCommentDialogComponent.open( this.dialog, params );

    dialog.afterClosed().subscribe( async  (response: IClusterCommentDialogResponse) => {

      this._log.debug('dialog.afterClosed().subscribe', 'result', response);
      if( response ) {
        clusterAnswers.update( answerSet );
        await FirebaseClusterAnswers.writeValueObject( this.firebase, this.sessionContext.clientKey, propertyKey, productType, clusterAnswers );
      }

    });
  }

  onEditChild( child: AppClusterNode ) {
    this._log.debug( 'onChildClick', 'child', child );

    const product = NocoDbProduct.INSTANCE;
    const childCluster = child.cluster;
    const clusterType: AppClusterType = product.getClusterType(this.productType, childCluster);

    const params: IClusterEditDialogParams = {
      name: childCluster.value.name,
      clusterType,
      applicableTypes: [clusterType],
      responsibility: childCluster.value.responsibility,
      deleteDisabled: childCluster.value.core,
    };

    const dialog = ClusterEditDialogComponent.open( this.dialog, params );
    dialog.afterClosed().subscribe( async  (response: IClusterEditDialogResponse) => {
      this._log.debug( 'dialog.afterClosed().subscribe', 'result', response );

      if( response ) {
        if( response.delete ) {
          this.deleteCluster.emit( child );
        } else {
          const cluster = this.clusterNode.cluster;
          childCluster.value.name = response.name;
          childCluster.value.responsibility = response.responsibility;
          const propertyKey = this.propertyService.propertyContext.propertyKey;
          const productType = this.propertyService.productType;
          await FirebaseCluster.writeReference( this.firebase, this.sessionContext.clientKey, propertyKey, productType, childCluster );
        }
      }
    })
  }

  onEvaluateChild(child: AppClusterNode) {
    this._log.debug('onChildClick', 'child', child);
    this._log.debug(`onEvaluateChild ${this.clusterNode.index} ${child.index}`);
    AppRouteManifest.CLUSTER_PAGE.navigateToPage( this.router, child.cluster._self.toString,0, this.clusterNode.index, child.index );
  }

  onAddSubSection() {
    this.addCluster.emit( this.clusterNode );
  }

  async onSectionInclude() : Promise<void> {
    const section = this.clusterNode;
    const parentInclude = section.cluster.value.include;
    await this.saveCluster(section.cluster);
    section.children.forEach(c => {
      if (c.cluster.value.include != parentInclude) {
        c.cluster.value.include = parentInclude;
        this.saveCluster(c.cluster);
      }
    });
    this.includeCluster.emit(this.clusterNode);
  }

  async onSubSectionInclude(appCluster: AppCluster) : Promise<void> {
    await this.saveCluster(appCluster);
    this.includeCluster.emit(this.clusterNode);
  }

  private async saveCluster(appCluster: AppCluster) : Promise<void> {
    await FirebaseCluster.writeReference( this.firebase, this.sessionContext.clientKey,
      this.propertyService.propertyContext.propertyKey, this.propertyService.productType,
      appCluster );
  }

  async ngOnInit() : Promise<void> {
    if (this.clusterNode.children.some(c => c.cluster.value.optional)) {
      this.hasAnyOptionalChildren = true;
    }
  }

  constructor( public router: Router,
               public dialog: MatDialog,
               public sessionContext: SessionContextProvider,
               public propertyService: PropertyService,
               public firebase: FirebaseConnectionService) {
    if(environment.productConfig.canCommentOnClusters) {
      this._commentQuestion = this.propertyService.propertyContext.product.questions.getQuestionById(AppQuestion.KEY_IDS.COMMENTS_QUESTION_ID);
      if(this._commentQuestion) {
        this.clusterQuestions = new AppQuestionSet( [this._commentQuestion.value]);
      }
    }
  }

  protected readonly EAppAuditState = EAppAuditState;
}
