import {Injectable} from '@angular/core';
import {LoggerFactory} from "../javascript.lib.mojo-base/log/LoggerFactory";
import {FirebaseConnectionService} from "../common/service.firebase-connection/FirebaseConnectionService";
import {SessionContextProvider} from "../service.session-context/session-context-provider";
import {AppAudit, EAppAuditState, IAppAuditMetaData} from "../javascript.lib.mojo-base/model/AppAudit";
import {FirebaseAudit} from "../javascript.lib.mojo-base/firebase/realtime-database/audit/FirebaseAudit";

@Injectable(
  {providedIn: 'root'}
)
export class AuditService {

  private static _log = LoggerFactory.build( 'AuditService' );

  public async getAllAppAudits() : Promise<AppAudit[]> {
    return  await FirebaseAudit.readAll(this.firebase, this.sessionContext.clientKey);
  }

  public async getAppAudits(propertyKeys: string[]) : Promise<AppAudit[] | null> {
    const allAudits =  await FirebaseAudit.readAll(this.firebase, this.sessionContext.clientKey);
    if (!allAudits) {
      return [];
    }

    const propertyKeySet = new Set(propertyKeys);
    return allAudits?.filter((a) => propertyKeySet.has(a.propertyKey));
  }

  public async getAppAudit(propertyKey: string) : Promise<AppAudit | null> {
    return await FirebaseAudit.readReference(this.firebase, this.sessionContext.clientKey, propertyKey);
  }

  public async getState(propertyKey: string) {
    return (await this.getAppAudit(propertyKey))?.value?.state ?? EAppAuditState.unknown;
  }

  public async complete(propertyKey: string) : Promise<void> {
    return this.setState(propertyKey, EAppAuditState.complete);
  }

  public async submit(propertyKey: string) : Promise<void> {
    return this.setState(propertyKey, EAppAuditState.submitted);
  }

  public async setState(propertyKey: string, state: EAppAuditState) : Promise<void> {
    let value = (await this.getAppAudit(propertyKey))?.value;

    if (!value) {
      value = {
        _meta: {
          version: [0]
        },
        propertyKey: propertyKey,
        state: state,
        timestamp: new Date().toISOString(),
      };
    } else if (value.state != state) {
      value.state = state;
      value.timestamp = new Date().toISOString();
    }

    await FirebaseAudit.writeValue(this.firebase, this.sessionContext.clientKey, value);
  }

  constructor(public firebase: FirebaseConnectionService,
              public sessionContext: SessionContextProvider) {
  }
}
