import { Component } from '@angular/core';
import {environment} from "../environments/environment";
import {ApplicationContextProvider} from "../service.application-context/application-context";
import {FirebaseConnectionService} from "../common/service.firebase-connection/FirebaseConnectionService";
import {FirestoreLoggerDelegate} from "../javascript.lib.mojo-base/log/FirestoreLoggerDelegate";
import {LoggerDelegate} from "../javascript.lib.mojo-base/log/LoggerDelegate";
import {Router, Event} from '@angular/router';
import {ILogger} from "../javascript.lib.mojo-base/log/Logger";
import {LoggerFactory} from "../javascript.lib.mojo-base/log/LoggerFactory";


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.scss'],
})
export class MyApp {

  private _log: ILogger = LoggerFactory.build( 'MyApp' );

  public get bannerText() : string {
    if (environment.environmentBanner) {
      return environment.environmentBanner;
    } else if (environment.isTest) {
      return "test";
    } else if (environment.isDevelopment) {
      return "dev";
    }
  }

  private _onRouterEvent( event: Event ) {
    // [How to detect a route change in Angular? - Stack Overflow](https://stackoverflow.com/questions/33520043/how-to-detect-a-route-change-in-angular)
    this._log.debug( 'event', event );
  }

  constructor( public applicationContext: ApplicationContextProvider,
               public firebase: FirebaseConnectionService,
               private router: Router ) {

    if( !environment.isDevelopment ) {
      LoggerDelegate.INSTANCE = new FirestoreLoggerDelegate( firebase.firestore );
    }

    this.router.events.subscribe((event: Event) => {
      this._onRouterEvent( event );
    });
  }

  protected readonly environment = environment;
}

