import {IProduct } from "./IProduct";

export class ProductHelper {

  public static cloneProduct( product: IProduct, excludePhotos = false ) {

    const answer: IProduct = {
      questions: product.questions,
      reportSections: product.reportSections,
      version: product.version,
      title: product.title,
      productType: product.productType,
      productId: product.productId,
      enumeratedConstants: product.enumeratedConstants,
    };

    return answer;
  }
}
