import {AppProperty} from "../javascript.lib.mojo-base/model/AppProperty";
import {IProduct} from "../model.product/IProduct";
import {ProductHelper} from "../model.product/ProductHelper";
import {LoggerFactory} from "../javascript.lib.mojo-base/log/LoggerFactory";
import {EvaluationStatus} from "../javascript.lib.mojo-base/model/evaluation/EvaluationStatus";
import {SessionContextProvider} from "../service.session-context/session-context-provider";
import {ProductBuilder} from "../service.product-builder/product-builder.service";

export class PropertyContext {

  private static log = LoggerFactory.build( 'PropertyContext' );

  public propertyKey: string;
  public completedPageHasBeenShown: boolean = false;
  public showCongratulationsText: boolean = true;
  public evaluationStatus: EvaluationStatus = new EvaluationStatus( null );

  private static async _getProduct( property: AppProperty,
                              productBuilder: ProductBuilder ): Promise<IProduct> {
    const productTemplate = await productBuilder.build(property.value.productType);

    if( property.value.excludePhotos ) {
      PropertyContext.log.debug( 'property.value.excludePhotos' );
      return ProductHelper.cloneProduct( productTemplate, true );
    }
    return productTemplate;
  }

  public static async build( sessionContext: SessionContextProvider,
                             property: AppProperty,
                             productBuilder: ProductBuilder): Promise<PropertyContext> {
    const product : IProduct = await PropertyContext._getProduct( property, productBuilder );
    return new PropertyContext( property, product, sessionContext );
  }

  private constructor( public property: AppProperty,
                       public product: IProduct,
                       public sessionContext: SessionContextProvider,) {
    this.propertyKey = property.propertyKey;
  }
}
