import {AppQuestion, ITypeBoolean, ITypeTernary} from "../AppQuestion";
import {AppAnswer} from "../AppAnswer";
import {ILogger} from "../../log/Logger";
import {LoggerFactory} from "../../log/LoggerFactory";
import {ValueHelper} from "../../util/ValueHelper";

export class QuestionScoreCalculator {

  private static _log: ILogger = LoggerFactory.build( 'QuestionScoreCalculator' );

  public calculate(question: AppQuestion, answer : AppAnswer) : AppAnswer | null {

    QuestionScoreCalculator._log.info("calculate");

    if (!question?.value || !answer?.value) {
      return answer;
    }

    let score: number = null;
    if (answer.value.needsAnswer && (question.value?.scoringRule ?? "default") !== "none") {
      try {
        if (question.isBoolean) {
          score = this._calculateBoolean(question, answer);
        } else if (question.isTernary) {
          score = this._calculateTernary(question, answer);
        } else if (question.isEnum) {
          score = this._calculateMultiChoice(question, answer);
        } else if (question.isCmMeasurement || question.isInteger || question.isFloat) {
          score = this._calculateNumber(question, answer);
        } else if (question.isPhoto || question.isLine || question.isText) {
          score = null;
        }
      } catch (e) {
        QuestionScoreCalculator._log.error("Error calculating score for Question / Answer", "questionId", question?.value?.nocoDbId ?? "??", "answerId", answer?.value?.$key ?? "??");
      }
    }

    answer.value.score = score;
    return answer;
  }

  private _calculateBoolean(question: AppQuestion, answer : AppAnswer) : number | null {
    const value = answer.value.value as boolean;
    const scoringRule = question?.value?.scoringRule ?? "default";

    if (scoringRule === "default" || scoringRule === "true") {
      return (value === true) ? 10 : 0;
    }
    if (scoringRule === "false") {
      return  (value === false) ? 10 : 0;
    }
    const scoring = (JSON.parse(question.value.scoringRule) as ITypeBoolean).scoring;
    if (scoring.onTrue && value === true) {
      return scoring.onTrue;
    } else if (value === false) {
      return scoring?.onFalse ?? 0;
    }
    return null;
  }

  private _calculateTernary(question: AppQuestion, answer : AppAnswer) : number | null {
    const value: number = ValueHelper.getIntegerValue(answer.value, 0)

    if (question?.value?.scoringRule) {
      const scoring = (JSON.parse(question.value.scoringRule) as ITypeTernary).scoring;

      if (value === 1) {
        return scoring.onTrue;
      } else if (value === 0) {
        return scoring.onFalse;
      } else if(scoring.onNotApplicable) {
        return scoring.onNotApplicable;
      }
      return null;
    }

    // default fallback
    if (value === 1) {
      return 10;
    } else if (value === 0) {
      return 0;
    } else {
      return null;
    }
  }

  private _calculateMultiChoice(question: AppQuestion, answer : AppAnswer) : number | null {
    const score = answer.getEnumScore();
    return (score) ? score : 0;
  }

  private _calculateNumber(question: AppQuestion, answer : AppAnswer) : number | null {
    QuestionScoreCalculator._log.info("_calculateNumber");

    const value: number = ValueHelper.getIntegerValue(answer.value, 0);

    const rawString = question.value.scoringRule;
    if (!rawString) {
      return null;
    }

    const segments = rawString.split(',');

    QuestionScoreCalculator._log.info("_calculateNumber", "rawString", rawString, "segments", segments);

    // <1:10, 1-2:5,  >2:2, >4:0
    for (let segment in segments) {
      const segmentParts = segments[segment].split(":");
      QuestionScoreCalculator._log.info("_calculateNumber", "segmentParts", segmentParts);
      if (segmentParts.length != 2) {
        continue;
      }

      const condition = segmentParts[0];
      const potentialScore = ValueHelper.valueToInteger(segmentParts[1]);

      if (this._testCondition(condition, value)) {
        return potentialScore;
      }
    }

    return null;
  }

  private _testCondition(condition: string, value: number) : boolean {
    QuestionScoreCalculator._log.info("_testCondition");

    if (condition.includes("-")) {
      const parts = condition.split("-");
      const leftPart = ValueHelper.valueToInteger(parts[0]);
      const rightPart = ValueHelper.valueToInteger(parts[1]);
      const lowerBound = Math.min(leftPart, rightPart);
      const upperBound = Math.max(leftPart, rightPart);
      QuestionScoreCalculator._log.info("_testCondition", parts, lowerBound, upperBound);

      if (lowerBound <= value && value <= upperBound) {
        QuestionScoreCalculator._log.info("_testCondition met", parts, lowerBound, upperBound);
        return true;
      }
    }

    if (/<|<=|==|>|>=|!=/.test(condition)) {
      return this._parseToken(condition, value);
    }

    return false;
  }

  private _parseToken(condition: string, value: number) : boolean  {
    const operator = condition.replace(/[0-9]|\s/g, '');
    const test = ValueHelper.valueToInteger(condition.replace(/[^0-9]|\s/g, ''));

    QuestionScoreCalculator._log.info("_parseToken", value, operator, test);

    switch (operator) {
      case "<=": return (value <= test);
      case "<": return (value < test);
      case "==": return (value == test);
      case ">=": return (value >= test);
      case ">": return (value > test);
      case "!=": return (value != test);
      default: false;
    }
  }

  constructor() {
  }
}
