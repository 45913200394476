import {NocoClusterId} from "./NocoCluster";
import {NocoProductId} from "./NocoProduct";
import {ValueObject} from "../../model/ValueObject";
import {NocoDbProjectProxy} from "../NocoDbProjectProxy";
import {IListingReponse} from "../ListingReponse";

export type NocoProductClusterId = string;

export interface INocoProductCluster {
  Id: NocoProductClusterId;
  ClusterId: NocoClusterId;
  ProductId: NocoProductId;
  Optional?: boolean;
  Essential?: boolean;
  Weight: number;
  AuditoryWeight: number;
  CognitiveWeight: number;
  MobilityWeight: number;
  VisualWeight: number;
}

export class NocoProductCluster extends ValueObject<INocoProductCluster> {
  protected onSetValue(value: INocoProductCluster | null) {
  }

  constructor(value: INocoProductCluster | null) {
    super(value);
    if (value) {
      this.value = value;
    }
  }
}

export class NocoProductClusterSet {
  private static readonly tableName = "product_cluster";
  private valuesByCompositeId: NocoProductCluster[][] = [];

  values: NocoProductCluster[] = [];
  valuesById: {[id: NocoProductClusterId]: NocoProductCluster} = {};

  public static async getValue( proxy: NocoDbProjectProxy ): Promise<IListingReponse<INocoProductCluster>> {
    return proxy.getView<INocoProductCluster>(this.tableName);
  }

  public static async build( proxy: NocoDbProjectProxy ): Promise<NocoProductClusterSet> {
    const value: IListingReponse<INocoProductCluster> = await proxy.getView<INocoProductCluster>(this.tableName);
    return new NocoProductClusterSet(value);
  }

  public constructor(public value: IListingReponse<INocoProductCluster>) {
    for (const rowValue of value.list) {
      const reference = new NocoProductCluster(rowValue);
      this.values.push(reference);
      this.valuesById[rowValue.Id] = reference;

      if (!this.valuesByCompositeId[rowValue.ProductId]) {
        this.valuesByCompositeId[rowValue.ProductId] = []
      }
      this.valuesByCompositeId[rowValue.ProductId][rowValue.ClusterId] = reference;
    }
  }

  public getForProduct(productId: NocoProductId, clusterId: NocoClusterId) : null | NocoProductCluster {
    if (!productId || !clusterId) {
      return null;
    }
    if (!this.valuesByCompositeId[productId]) {
      return null;
    }
    return this.valuesByCompositeId[productId][clusterId];
  }
}
