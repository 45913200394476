<div *ngIf="ready">
  <mat-toolbar cclass="header">
    <mat-toolbar-row style="display: grid; grid-template-columns: 1fr auto">
      <div style="justify-self: center">
      </div>
      <button mat-button (click)="dismiss()">
        <span class="material-icons">close</span>
        <span>&nbsp;CLOSE</span>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>


  <mat-dialog-content cclass="dialog-content">


    <div style="display: grid; grid-template-columns: 10% 80% 10%">

      <div></div>
      <div>

        <div *ngIf="falseyDependentAnswer">
          <div cclass="help-text">
            This question is disabled because of the answer supplied for:
            <br>
            <strong>
              {{falseyDependentAnswer.question.value.label}}
            </strong>
          </div>
          <div style="width: 100%; padding-left: 8px; padding-right: 8px;">
            <div style="width: 100%; height: 1px; background-color: #370178"></div>
          </div>
          <hr>
        </div>

        <div cclass="help-text">
          {{answer.question.value.helpText}}
        </div>

        <div *ngIf="cmMeasurementMinValue ?? 0 != 0">
          <div cclass="help-text">
            <strong>Minimum size: {{cmMeasurementMinValue}}cm</strong>
          </div>

        </div>
        <div *ngIf="null!=cmMeasurementMaxValue">
          <div cclass="help-text">
            <strong>Maximum size: {{cmMeasurementMaxValue}}cm</strong>
          </div>
        </div>

      </div>
      <div></div>
    </div>

    <div cclass="help-images" *ngFor="let helpImagePath of helpImagePaths">
      <div></div>
      <div>
        <img src="{{helpImagePath}}" fetchpriority="high">
      </div>
      <div></div>
    </div>

  </mat-dialog-content>
</div>
