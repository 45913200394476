import {ValueObject} from "../ValueObject";
import {AppQuestion, IAppDependantDescriptor} from "../AppQuestion";
import {ILogger} from "../../log/Logger";
import {LoggerFactory} from "../../log/LoggerFactory";
import {QuestionKey} from "../QuestionKey";

export interface IAppPageDefinition {
  sectionId?: string;
  questionKeys: QuestionKey[];
}

export class AppPageDefinition extends ValueObject<IAppPageDefinition> {

  private _log: ILogger = LoggerFactory.build( 'AppPageDefinition' );

  public questions: AppQuestion[] = [];

  protected onSetValue(value: IAppPageDefinition | null) {
  }

  constructor( value: IAppPageDefinition ) {
    super(value);
  }
}



