import {IAppPageDefinition} from "../javascript.lib.mojo-base/model/app/AppPageDefinition";
import {AppQuestionSet} from "../javascript.lib.mojo-base/model/AppQuestionSet";
import {IEnumeratedConstant} from "../javascript.lib.mojo-base/model/EnumeratedConstant";
import {EProductType} from "../javascript.lib.mojo-base/model/ProductType";
import {IProduct} from "./IProduct";
import {ReportSection} from "./ReportSection";

export class Product implements IProduct {

  public questions: AppQuestionSet;
  public reportSections: ReportSection[];
  public summaryStructure?: IAppPageDefinition[];
  public enumeratedConstants: IEnumeratedConstant[];
  public version: string;
  public productType: EProductType;
  public title: string;
  public productId: number;

  public static INSTANCE = new Product();

  constructor() {
  }
}


