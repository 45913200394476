<mat-sidenav-container aclass="sidenav-container">

  <mat-sidenav #sidenav aclass="sidenav" [mode]="'over'">
    <app-side-menu homeDisabled="true" [sidenav]="sidenav"></app-side-menu>
  </mat-sidenav>

  <mat-sidenav-content>
    <div aclass="page-container">

      <mat-toolbar aclass="header-toolbar">
        <mat-toolbar-row >
          <button mat-button
                  (click)="sidenav.toggle()">
            <span class="material-icons">menu</span>
          </button>
          <span style="flex: 1 1 auto;"></span>
          <img src="assets/mobility-mojo/logo-and-text.white.png"
               aclass="header-toolbar-logo">
          <span style="flex: 1 1 auto;"></span>
          <div style="width: 64px; height: 10px;">
          </div>
        </mat-toolbar-row>
      </mat-toolbar>

      <div aclass="page-content">

        <app-session-checker *ngIf="!sessionContext.sessionIsReady"></app-session-checker>

        <div *ngIf="sessionContext.sessionIsReady">
          <div fxLayout="column" fxLayoutGap="10px" cclass="instructions">
            <div fxLayout="row" fxLayoutAlign="start center">
              <div fxFlex cclass="instruction-line">
                <strong>
                  Choose a property to audit.
                </strong>
              </div>
            </div>
          </div>

          <search-bar [placeholder]="'Search your properties'"
                      [unfilteredList]="this.properties"
                      (filteredListChange)="onFilteredPropertiesChange($event)"
          ></search-bar>

          <mat-card cclass="property-select" *ngFor="let property of filteredProperties">

            <div cclass="property-details">
              <property-details [property]="property"></property-details>
              <span class="state">{{getStateLabel(property.propertyKey)}}</span>
            </div>

            <div cclass="property-select-button">
              <button mat-raised-button [ngClass]="currentPropertyKey === property.propertyKey ? 'current' : ''" cclass="property" (click)="onSelectProperty(property)">
                {{ProductTypeOptions.getLabel(property.value.productType)}} Audit {{currentPropertyKey === property.propertyKey ? '(current)' : ''}}</button>
            </div>

          </mat-card>
        </div>

      </div>

      <mat-toolbar aclass="footer-toolbar">
        <mat-toolbar-row>
        </mat-toolbar-row>
      </mat-toolbar>

    </div>


  </mat-sidenav-content>

</mat-sidenav-container>


