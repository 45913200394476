import {ValueObject} from "../../model/ValueObject";
import {NocoDbProjectProxy} from "../NocoDbProjectProxy";
import {EProductType} from "../../model/ProductType";
import {IListingReponse} from "../ListingReponse";

export type NocoProductId = number;

export interface INocoProduct {
  Id: NocoProductId;
  Title: string;
  VersionMajor: number;
  VersionMinor: number;
}

export class NocoProduct extends ValueObject<INocoProduct> {
  protected onSetValue(value: INocoProduct | null) {
  }

  constructor(value: INocoProduct | null) {
    super(value);
    if (value) {
      this.value = value;
    }
  }
}

export class NocoProductSet {

  private static tableName: string = 'product';

  values: NocoProduct[] = [];
  valuesById: {[id: number]: NocoProduct} = {};

  anPostDsu: NocoProduct;
  anPostCombined: NocoProduct;
  anPostMailCentre: NocoProduct;
  banking: NocoProduct;
  banking_2024: NocoProduct;
  facilities: NocoProduct;
  foodAndBeverage: NocoProduct;
  generic: NocoProduct;
  hotels_2024: NocoProduct;
  manufacturing: NocoProduct;
  manufacturingCampus: NocoProduct;
  postOffice: NocoProduct;
  publicRealm: NocoProduct;
  retail: NocoProduct;
  failteIrelandRetail: NocoProduct;
  failteIrelandAccommodation: NocoProduct;
  failteIrelandAttractions: NocoProduct;
  failteIrelandOutdoorSpaces: NocoProduct;
  unity: NocoProduct;

  getProduct(productType: EProductType): NocoProduct {

    if (productType === EProductType.unity) {
      return this.unity;
    }

    if(productType === EProductType.anPostCombined) {
      return this.anPostCombined;
    }

    if( productType === EProductType.anPostDsu ) {
      return this.anPostDsu;
    }

    if( productType === EProductType.anPostMailCentre ) {
      return this.anPostMailCentre;
    }

    if( productType === EProductType.banking) {
      return this.banking;
    }

    if (productType === EProductType.banking_2024) {
      return this.banking_2024;
    }

    if (productType === EProductType.hotels_2024) {
      return this.hotels_2024;
    }

    if( productType === EProductType.facilities) {
      return this.facilities;
    }

    if( productType === EProductType.foodAndBeverage ) {
      return this.foodAndBeverage;
    }

    if( productType === EProductType.generic) {
      return this.generic;
    }

    if( productType === EProductType.manufacturing) {
      return this.manufacturing;
    }

    if( productType === EProductType.manufacturingCampus ) {
      return this.manufacturingCampus;
    }

    if( productType === EProductType.postOffice) {
      return this.postOffice;
    }

    if( productType === EProductType.publicRealm) {
      return this.publicRealm;
    }

    if( productType === EProductType.retail ) {
      return this.retail;
    }

    if( productType === EProductType.failteIrelandRetail ) {
      return this.failteIrelandRetail;
    }

    if( productType === EProductType.failteIrelandAccommodation ) {
      return this.failteIrelandAccommodation;
    }

    if ( productType === EProductType.failteIrelandAttractions ) {
      return this.failteIrelandAttractions;
    }

    if ( productType === EProductType.failteIrelandOutdoorSpaces) {
      return this.failteIrelandOutdoorSpaces;
    }

    return null;
  }

  public static async getValue( proxy: NocoDbProjectProxy ): Promise<IListingReponse<INocoProduct>> {
    return proxy.getView<INocoProduct>(this.tableName);
  }

  public static async build( proxy: NocoDbProjectProxy ): Promise<NocoProductSet> {
    const value: IListingReponse<INocoProduct> = await proxy.getView<INocoProduct>(this.tableName);
    return new NocoProductSet( value );
  }

  public constructor(public value: IListingReponse<INocoProduct>) {

    for( const rowValue of value.list ) {

      const reference = new NocoProduct( rowValue );
      this.values.push( reference );
      this.valuesById[rowValue.Id] = reference;

      if ( 'An Post - Combined' === reference.value.Title) {
        this.anPostCombined = reference;
      } else if ( 'An Post - DSU' === reference.value.Title) {
        this.anPostDsu = reference;
      } else if ( 'An Post - Mail Centre' === reference.value.Title) {
        this.anPostMailCentre = reference;
      } else if( 'Banking' === reference.value.Title) {
        this.banking = reference;
      } else if('Banking 2024' === reference.value.Title) {
        this.banking_2024 = reference;
      } else if ('Hotels 2024' === reference.value.Title) {
        this.hotels_2024 = reference;
      } else if( 'Facilities' === reference.value.Title) {
        this.facilities = reference;
      } else if( 'Food and Beverage' === reference.value.Title) {
        this.foodAndBeverage = reference;
      } else if( 'Generic' === reference.value.Title) {
        this.generic = reference;
      } else if( 'Manufacturing' === reference.value.Title) {
        this.manufacturing = reference;
      } else if ( 'Manufacturing - Campus' === reference.value.Title) {
        this.manufacturingCampus = reference;
      } else if ( 'Post Office' === reference.value.Title) {
        this.postOffice = reference;
      } else if ( 'Public Realm' === reference.value.Title) {
        this.publicRealm = reference;
      } else if ( 'Retail' === reference.value.Title) {
        this.retail = reference;
      } else if ( 'An Post - DSU' === reference.value.Title) {
        this.anPostDsu = reference;
      } else if ( 'Failte Ireland - Retail' === reference.value.Title) {
        this.failteIrelandRetail = reference;
      } else if ( 'Failte Ireland - Accommodation' === reference.value.Title) {
        this.failteIrelandAccommodation = reference;
      } else if ( 'Failte Ireland - Attractions' === reference.value.Title) {
        this.failteIrelandAttractions = reference;
      } else if ( 'Failte Ireland - Outdoor Spaces' === reference.value.Title ) {
        this.failteIrelandOutdoorSpaces = reference;
      } else if ('Unity' === reference.value.Title) {
        this.unity = reference;
      }
    }
  }

}

