import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AppAnswer} from "../../../javascript.lib.mojo-base/model/AppAnswer";
import {Subscriptions2} from "../../../javascript.lib.mojo-base/util/Subscriptions2";
import {AppCluster} from "../../../javascript.lib.mojo-base/model/app.cluster/AppCluster";
import {ILogger} from "../../../javascript.lib.mojo-base/log/Logger";
import {LoggerFactory} from "../../../javascript.lib.mojo-base/log/LoggerFactory";

@Component({
  selector: 'aspect-header',
  styleUrls: ['aspect-header.scss'],
  templateUrl: 'aspect-header.html'
})
export class AspectHeaderComponent implements OnInit, OnDestroy {

  private _answersSubscriptions: Subscriptions2 = new Subscriptions2();
  private _log: ILogger = LoggerFactory.build( 'AspectHeaderComponent' );

  progress: number;

  @Input() title: string = null;
  @Input() essential: boolean = false;
  @Input() cluster: AppCluster = null;
  @Input() answers: AppAnswer[] = null;

  private setProgress() : void {
    const haveAnswer = this.answers.filter((a) => a.answer.value.hasAnswer && !a.answer.question.isPhoto).length;
    const needAnswer = this.answers.filter((a) => a.answer.value.hasAnswer || a.answer.value.needsAnswer && !a.answer.question.isPhoto).length;
    if( null == haveAnswer ||
      null == needAnswer ||
      haveAnswer === 0) {
      this.progress = 0;
    }
    if( haveAnswer === needAnswer  ) {
      this.progress = 100;
    } else {
      this.progress = Math.round(100 * (haveAnswer/needAnswer));
    }
    this.cluster.value.completed = this.progress == 100;
  }

  ngOnInit(): void {
    for (let answer of this.answers) {
      this._answersSubscriptions.subscribe(answer.getSubject(), (a) => {
        this.setProgress();
      });
    }
  }

  ngOnDestroy() {
    this._answersSubscriptions.unsubscribe();
  }

  constructor() {
  }
}
