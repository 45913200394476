import {ILogger} from "../log/Logger";
import {LoggerFactory} from "../log/LoggerFactory";
import {IListingReponse} from "./ListingReponse";
import {FirebaseConnectionService} from "../../common/service.firebase-connection/FirebaseConnectionService";
import {FirebaseNocoResponseCache} from "../firebase/realtime-database/product/FirebaseNocoResponseCache";
import {environment} from "../../environments/environment";
import {XmlHttpNocoDbProxy} from "./XmlHttpNocoDbProxy";

interface IFieldMap {
  fieldName: string;
  legacyName: string;
}

export class NocoDbProjectProxy {

  private static _log: ILogger = LoggerFactory.build( 'NocoDbProjectProxy' );
  private _fieldMaps: {[tableName: string]: IFieldMap[]} = {};
  private _nocoConnection: XmlHttpNocoDbProxy;
  private readonly _projectId: string;

  private async ensureConnection() : Promise<void> {
    if (this._nocoConnection) {
      return;
    }

    try {
      this._nocoConnection = new XmlHttpNocoDbProxy(environment.nocoDbConfig);
      await this._nocoConnection.signIn();
    }
    catch (e) {
      NocoDbProjectProxy._log.error("Unable to connect!", e);
      throw e;
    }
  }

  private async tryGetResponseFromCache<T>(table: string) : Promise<IListingReponse<T>> {
    try{
      return await FirebaseNocoResponseCache.read<T>(this.firebase, table) as IListingReponse<T>;
    }
    catch (e){
      NocoDbProjectProxy._log.error("Unable to read response from cache", "table", table, e);
    }
  }

  private async tryWriteResponseToCache<T>(table: string, data: IListingReponse<T>) : Promise<void> {
    try {
      await FirebaseNocoResponseCache.write<T>(this.firebase, table, data);
    }
    catch (e) {
      NocoDbProjectProxy._log.error("Unable to write response to cache", "table", table, e);
    }
  }

  private mapToLegacyView<T>( table: string, viewResponse: IListingReponse<T> ) {

    if(viewResponse.list.length === 0) {
      return;
    }

    let fieldMappings = this._fieldMaps[table];
    if(!fieldMappings) {

      this._fieldMaps[table] = fieldMappings = [];

      const row0 = viewResponse.list[0];

      for( const fieldName of Object.keys( row0 ) ) {
        const tokens = fieldName.split( '_');
        let legacyName = '';
        for( const token of tokens ) {

          // [How do I make the first letter of a string uppercase in JavaScript? - Stack Overflow](https://stackoverflow.com/questions/1026069/how-do-i-make-the-first-letter-of-a-string-uppercase-in-javascript)
          legacyName += token.charAt(0).toUpperCase() + token.slice(1);
        }
        fieldMappings.push( {
          fieldName,
          legacyName
        });
      }
    }

    for(let i = 0; i < viewResponse.list.length; i++) {
      const oldRow = viewResponse.list[i];
      const newRow = {};
      for( const fieldMapping of fieldMappings) {
        newRow[fieldMapping.legacyName] = oldRow[fieldMapping.fieldName];
      }
      // @ts-ignore
      viewResponse.list[i] = newRow;
    }
  }

  public async getView<T>( table: string, view: string = 'API' ): Promise<IListingReponse<T>> {

    let response : IListingReponse<T>;
    response = await this.tryGetResponseFromCache(table);
    if (response) {
      return response;
    }

    // we don't have anything in the cache for that table,
    // so need to connect to the database to get it
    await this.ensureConnection();

    // https://docs.nocodb.com/0.109.7/developer-resources/rest-apis/#query-params

    const limit = 1000;

    const url = `${this._nocoConnection.nocoDbConfig.httpServer}/api/v1/db/data/v1/${this._projectId}/${table}/views/${view}?limit=${limit}&t=`
    NocoDbProjectProxy._log.debug('pageUrl', url);

    response = await this._nocoConnection.get(url) as IListingReponse<T>;

    let offset = limit;
    let isLastPage = response.pageInfo.isLastPage;

    while(!isLastPage) {

      const pageUrl = `${url}&offset=${offset}`;
      NocoDbProjectProxy._log.debug('pageUrl', pageUrl);

      const pageResponse = await this._nocoConnection.get(pageUrl) as IListingReponse<T>;
      NocoDbProjectProxy._log.debug('pageResponse', pageResponse);

      isLastPage = pageResponse.pageInfo.isLastPage;

      for(const e of pageResponse.list) {
        response.list.push(e);
      }

      offset+=limit;
    }

    this.mapToLegacyView(table, response);

    // don't cache "deleted" items
    response.list = response.list.filter(item => !(item["Deleted"] == 1));

    await this.tryWriteResponseToCache<T>(table, response);

    return response;
  }

  public static build(firebase: FirebaseConnectionService): NocoDbProjectProxy {
    return new NocoDbProjectProxy(firebase);
  }

  private constructor(public firebase: FirebaseConnectionService) {
    this._projectId = environment.nocoDbConfig.projectId;
  }
}
