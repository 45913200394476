import {NocoDbProjectProxy} from "./NocoDbProjectProxy";
import {NocoProductId, NocoProductSet} from "./model/NocoProduct";
import {NocoEnumeratedTypeSet} from "./model/NocoEnumeratedType";
import {NocoQuestionType, NocoQuestionTypeSet} from "./model/NocoQuestionType";
import {NocoEnumeratedValue, NocoEnumeratedValueSet} from "./model/NocoEnumeratedValue";
import {NocoEvaluationQuestion, NocoEvaluationQuestionSet} from "./model/NocoEvaluationQuestion";
import {NocoEvaluationQuestionTextAltSet} from "./model/NocoEvaluationQuestionTextAlt";
import {NocoEvaluationSectionSet} from "./model/NocoEvaluationSection";
import {NocoProductEvaluationSet} from "./model/NocoProductEvaluation";
import {AppQuestionSet} from "../model/AppQuestionSet";
import {IProduct} from "../../model.product/IProduct";
import {EProductType} from "../model/ProductType";
import {AppQuestion, IAppDependantDescriptor, IAppQuestion, IType, ITypeEnum} from "../model/AppQuestion";
import {ILogger} from "../log/Logger";
import {LoggerFactory} from "../log/LoggerFactory";
import {QuestionKey} from "../model/QuestionKey";
import {INocoDependantDescriptor, NocoDependantDescriptor} from "./model/NocoDependantDescriptor";
import {NocoCluster, NocoClusterSet} from "./model/NocoCluster";
import {NocoClusterQuestionSet} from "./model/NocoClusterQuestion";
import {NocoClusterHierarchySet} from "./model/NocoClusterHierarchy";
import {AppClusterType} from "../model/app.cluster/AppClusterType";
import {AppCluster} from "../model/app.cluster/AppCluster";
import {IEnumeratedConstant} from "../model/EnumeratedConstant";
import {Product} from "../../model.product/Product";
import {NocoProductCluster, NocoProductClusterSet} from "./model/NocoProductCluster";
import {NocoProductQuestionSet} from "./model/NocoProductQuestion";

export class NocoDbProduct {

  private _log: ILogger = LoggerFactory.build( 'NocoDbProduct' );

  public static INSTANCE: NocoDbProduct = null;

  products: NocoProductSet;
  enumeratedTypes: NocoEnumeratedTypeSet;
  enumeratedValues: NocoEnumeratedValueSet;
  questionTypes: NocoQuestionTypeSet;
  evaluationQuestions: NocoEvaluationQuestionSet;
  evaluationQuestionTextAlts: NocoEvaluationQuestionTextAltSet;
  evaluationSections: NocoEvaluationSectionSet;
  productEvaluations: NocoProductEvaluationSet;
  clusters: NocoClusterSet;
  clusterQuestions: NocoClusterQuestionSet;
  clusterHierarchies: NocoClusterHierarchySet;
  productClusters: NocoProductClusterSet;
  productQuestions: NocoProductQuestionSet;
  version: string = null;
  productId: number;

    public static async load( proxy: NocoDbProjectProxy ): Promise<NocoDbProduct> {

    const nocoDbProduct = new NocoDbProduct();

    nocoDbProduct.products = await NocoProductSet.build( proxy );
    nocoDbProduct.enumeratedTypes = await NocoEnumeratedTypeSet.build( proxy );
    nocoDbProduct.questionTypes = await NocoQuestionTypeSet.build( proxy );
    nocoDbProduct.enumeratedValues = await NocoEnumeratedValueSet.build( proxy );
    nocoDbProduct.evaluationQuestions = await NocoEvaluationQuestionSet.build( proxy );
    nocoDbProduct.evaluationQuestionTextAlts = await NocoEvaluationQuestionTextAltSet.build( proxy );
    nocoDbProduct.evaluationSections = await NocoEvaluationSectionSet.build( proxy );
    nocoDbProduct.productEvaluations = await NocoProductEvaluationSet.build( proxy );
    nocoDbProduct.clusters = await NocoClusterSet.build( proxy );
    nocoDbProduct.clusterQuestions = await NocoClusterQuestionSet.build( proxy );
    nocoDbProduct.clusterHierarchies = await NocoClusterHierarchySet.build(proxy);
    nocoDbProduct.productQuestions = await NocoProductQuestionSet.build(proxy);
    nocoDbProduct.productClusters = await NocoProductClusterSet.build(proxy);

    nocoDbProduct.version = "5.0";

    NocoDbProduct.INSTANCE = nocoDbProduct;

    return nocoDbProduct;
  }

  getClusterType(productType: EProductType, cluster: AppCluster): AppClusterType|null {
    const product = this.products.getProduct(productType);
    const productId = product.value.Id;
    const clusterId = cluster.value.clusterTypeId
    const nocoCluster: NocoCluster = this.clusters.valuesById[clusterId];
    const nocoProductCluster: NocoProductCluster = this.productClusters.getForProduct(productId, clusterId);
    return AppClusterType.build( nocoCluster, nocoProductCluster );
  }

  getClusterTypeRoot(productType: EProductType): AppClusterType|null {
    const product = this.products.getProduct(productType);
    const productId = product.value.Id;
    const roots = this.clusterHierarchies.getRoots();

    for(const root of roots) {
      const nocoCluster: NocoCluster = this.clusters.valuesById[root.value.Parent];
      const clusterId = nocoCluster.value.Id;
      if(nocoCluster.value.ProductId === productId) {
        this._log.info("Found root", productId, nocoCluster.value.Name);
        const nocoProductCluster: NocoProductCluster = this.productClusters.getForProduct(productId, clusterId);
        return AppClusterType.build(nocoCluster, nocoProductCluster);
      }
    }
    return null;
  }

  getClusterTypeChildren(parentDefinition: AppClusterType, onlyStarter: boolean = false): AppClusterType[] {
    if (!parentDefinition) {
      return;
    }

    const appClusterTypes: AppClusterType[] = [];
    for(const clusterHierarchy of this.clusterHierarchies.values) {

      if(onlyStarter && 1 !== clusterHierarchy.value.Starter) {
        continue;
      }

      if(clusterHierarchy.value.Parent === parentDefinition.nocoCluster.value.Id &&
        clusterHierarchy.value.Parent !== clusterHierarchy.value.Child
      ) {
        const childCluster: NocoCluster = this.clusters.valuesById[clusterHierarchy.value.Child];
        if(!childCluster) {
          continue;
        }

        const clusterId = childCluster.value.Id;
        const productId = clusterHierarchy.value.ProductId;
        const childProductCluster = this.productClusters.getForProduct(productId, clusterId);

        if (!childProductCluster) {
          this._log.warn("No ProductCluster found!", "productId", productId, "clusterId", clusterId);
        }

        appClusterTypes.push(AppClusterType.build(childCluster, childProductCluster));
      }
    }

    return appClusterTypes;
  }

  getQuestionType( question: NocoEvaluationQuestion ): [string,IType]|null {

    const nocoQuestionType: NocoQuestionType = this.questionTypes.questionTypesByKey[question.value.QuestionTypeId];
    if( !nocoQuestionType ) {
      this._log.warn( '!nocoQuestionType', 'question', question );
      return null;
    }

    if( nocoQuestionType.isBoolean() ) {
      const answer: [string,IType] = [
        AppQuestion.TYPE_BOOLEAN,
        {
          typeBoolean: {
            scoring: {
              onTrue: 0,
              onFalse: 0
            }
          }
        }
      ];
      return answer;
    }

    if( nocoQuestionType.isEnumerated() ) {
      const answer: [string,IType] = [
        AppQuestion.TYPE_ENUM,
        {
          typeEnum: {
            options: [],
            scoring: [],
          }
        }
      ];
      return answer;
    }

    if( nocoQuestionType.isMeasurement() ) {
      const answer: [string,IType] = [
        AppQuestion.TYPE_CM_MEASUREMENT,
        {
          typeCmMeasurement: {
            minValue: 0,
            maxValue: Number.MAX_VALUE,
            scoring: []
          }
        }
      ];
      return answer;
    }

    if( nocoQuestionType.isNumeric() ) {
      const answer: [string,IType] = [
        AppQuestion.TYPE_INTEGER,
        {
          typeInteger: {
            minValue: 0,
            maxValue: Number.MAX_VALUE,
            scoring: []
          }
        }
      ];
      return answer;
    }

    if( nocoQuestionType.isPhoto() ) {
      const answer: [string,IType] = [
        AppQuestion.TYPE_PHOTO,
        {
          typePhoto: {}
        }
      ];
      return answer;
    }

    if( nocoQuestionType.isTernary() ) {
      const answer: [string,IType] = [
        AppQuestion.TYPE_TERNARY,
        {
          typeTernary: {
            scoring: {
              onTrue: 0
            }
          }
        }
      ];
      return answer;
    }

    if( nocoQuestionType.isTextual() ) {
      const answer: [string,IType] = [
        AppQuestion.TYPE_TEXT,
        {
          typeText: {
            scoring: {}
          }
        }
      ];
      return answer;
    }

    this._log.warn( 'unsupported question type', 'nocoQuestionType', nocoQuestionType );
    return null;
  }

  private _tryGetDependantDescriptor( nocoQuestion: NocoEvaluationQuestion ): IAppDependantDescriptor|null {

    try {
      const dependency: INocoDependantDescriptor = JSON.parse(nocoQuestion.value.DependencyJson );
      return NocoDependantDescriptor.toDependantDescriptor( dependency );
    } catch ( e ) {
      this._log.error( e, 'nocoQuestion', nocoQuestion );
    }

    return null;
  }

  private _initDependency(pwaQuestion: IAppQuestion, nocoQuestion: NocoEvaluationQuestion) {

    if( nocoQuestion.value.DependencyJson ) {
      pwaQuestion.dependant = this._tryGetDependantDescriptor( nocoQuestion );
    } else if( nocoQuestion.value.DependencyId ) {
      const dependentQuestion: NocoEvaluationQuestion = this.evaluationQuestions.valuesById[nocoQuestion.value.DependencyId];
      if( !dependentQuestion ) {
        this._log.warn( '!dependentQuestion', 'nocoQuestion.value.DependencyId', nocoQuestion.value.DependencyId );
      } else {
        pwaQuestion.dependant = {
          questionKey: dependentQuestion.value.MysteriousTechColumn
        }
      }
    }
  }

  private _buildQuestion(productId: NocoProductId, key: QuestionKey): IAppQuestion|null {

    key = key.trim();

    const nocoQuestion = this.evaluationQuestions.valuesByMysteriousTechColumn[key];
    const nocoProductQuestion = this.productQuestions.getForProduct(productId, nocoQuestion.value.Id);

    if(!nocoQuestion) {
      this._log.error( `!nocoQuestion .${key}.`, 'key', key );
      return null;
    }

    const questionType = this.getQuestionType( nocoQuestion );
    if(!questionType) {
      this._log.error( '!questionType', 'nocoQuestion', nocoQuestion );
      return null;
    }

    const question: IAppQuestion = {
      key,
      label: nocoQuestion.value.QuestionText,
      helpImageFilename: nocoQuestion.value.HelpImageFilename,
      helpText: nocoQuestion.value.InfoBox,
      popupLabel: nocoQuestion.value.PopupWording,
      maximumScore: nocoQuestion.value.MaxPoints,
      type: questionType[0],
      type2: questionType[1],
      nocoDbId: nocoQuestion.value.Id,
      typicalLowValue: nocoQuestion.value.TypicalLowValue,
      typicalHighValue: nocoQuestion.value.TypicalHighValue,
      auditoryWeight: nocoProductQuestion?.value.AuditoryWeight ?? 1,
      cognitiveWeight: nocoProductQuestion?.value.CognitiveWeight ?? 1,
      mobilityWeight: nocoProductQuestion?.value.MobilityWeight ?? 1,
      visualWeight: nocoProductQuestion?.value.VisualWeight ?? 1,
      dependencyRule: nocoProductQuestion?.value.DependencyRule ?? null,
      scoringRule: nocoProductQuestion?.value.ScoringRule ?? null,
    }

    this._initDependency(question, nocoQuestion);
    nocoQuestion.initQuestionScoring(question);

    return question;
  }

  private _getTypeEnum( nocoQuestion: NocoEvaluationQuestion ): ITypeEnum {

    const answer: ITypeEnum = {
      options: [],
      scoring: []
    }

    const enumeratedType = this.enumeratedTypes.enumeratedTypesById[nocoQuestion.value.EnumeratedTypeId];
    if( !enumeratedType ) {
      this._log.error( '!enumeratedType', 'nocoQuestion.value.EnumeratedTypeId', nocoQuestion.value.EnumeratedTypeId, 'nocoQuestion.value', nocoQuestion.value );
      return answer;
    }

    answer.enumeratedTypeId = nocoQuestion.value.EnumeratedTypeId;

    const values: NocoEnumeratedValue[] = this.enumeratedValues.getValues( enumeratedType );
    if( !values ) {
      this._log.error( '!values', 'enumeratedType', enumeratedType );
      return answer;
    }

    for( const value of values ) {
      answer.options.push( value.toEnumeratedConstant() );
      answer.scoring.push( value.toEnumScore() );
    }

    return answer;
  }

  private _updateAltText( questionSet: AppQuestionSet, productType: EProductType ) {

    const product = this.products.getProduct( productType );
    if (!product) {
      this._log.error(`${productType} not found.`);
    }
    const altTexts = this.evaluationQuestionTextAlts.filterByProduct( product );
    for( const altText of altTexts ) {
      const questionId: number = altText.value.EvaluationQuestionId;

      const targetQuestion: AppQuestion|null = questionSet.getQuestionByNocoDbId( questionId );
      if( targetQuestion ) {
        if( altText.value.QuestionText ) {
          targetQuestion.value.label = altText.value.QuestionText;
        }
        if( altText.value.InfoBox ) {
          targetQuestion.value.helpText = altText.value.InfoBox;
        }
      }
    }
  }

  private _buildQuestions(productType: EProductType): AppQuestionSet {

    this._log.info("_buildQuestions", productType);

    const appQuestions: IAppQuestion[] = [];
    const product = this.products.getProduct(productType);

    for(const nocoQuestion of this.evaluationQuestions.values) {
      const newQuestion = this._buildQuestion(product.value.Id, nocoQuestion.value.MysteriousTechColumn);
      if(newQuestion) {
        if(nocoQuestion.isEnum) {

            const typeEnum = this._getTypeEnum(nocoQuestion);
            newQuestion.type = AppQuestion.TYPE_ENUM;
            newQuestion.type2 = {
              typeEnum
            };
        }
        appQuestions.push( newQuestion );
      }
    }

    const appQuestionSet = new AppQuestionSet( appQuestions );
    this._updateAltText( appQuestionSet, productType );
    return appQuestionSet;
  }

  getAllEnums(): IEnumeratedConstant[] {
    const enumeratedConstants : IEnumeratedConstant[] = this.enumeratedValues.enumeratedValues.map((v) => {
      return v.toEnumeratedConstant();
    });
    return enumeratedConstants;
  }

  buildProduct( productType: EProductType ): IProduct {

    const original = Product.INSTANCE;
    const questions = this._buildQuestions(productType);
    const vertical = this.products.getProduct(productType).value;
    const title = vertical.Title;
    const version = `${vertical.VersionMajor}.${vertical.VersionMinor}`;
    const productId = vertical.Id
    const enumeratedConstants = this.getAllEnums();
    original.productId = productId;

    const answer: IProduct = {
      productType,
      productId,
      title,
      version,
      questions,
      reportSections: original.reportSections,
      enumeratedConstants: enumeratedConstants
    }

    if (original.summaryStructure) {
      answer.summaryStructure = original.summaryStructure;
    }

    return answer;
  }

  private constructor() {
  }
}
