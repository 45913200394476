import {Component, Inject, Input} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {DialogConfig} from "../../../common/angular/DialogConfig";
import {ILogger} from "../../../javascript.lib.mojo-base/log/Logger";
import {LoggerFactory} from "../../../javascript.lib.mojo-base/log/LoggerFactory";
import {AppAnswer} from "../../../javascript.lib.mojo-base/model/AppAnswer";


export interface IClusterCommentDialogParams {
  clusterName: string;
  comment: AppAnswer;
}

export interface IClusterCommentDialogResponse {
  comment: AppAnswer;
}

@Component({
  selector: 'cluster-comment-dialog',
  templateUrl: 'cluster-comment-dialog.html',
  styleUrls: ['cluster-comment-dialog.scss']
})
export class ClusterCommentDialogComponent {

  public static PANEL_CLASS = "cluster-comment-dialog";

  isNew: boolean;

  private _log: ILogger = LoggerFactory.build( 'ClusterDetailDialogComponent' );

  public params: IClusterCommentDialogParams;

  static open(dialog: MatDialog, params: IClusterCommentDialogParams ): MatDialogRef<ClusterCommentDialogComponent> {

    const answer = dialog.open<ClusterCommentDialogComponent, IClusterCommentDialogParams, IClusterCommentDialogResponse>
    ( ClusterCommentDialogComponent,
      {
        data: params,
        panelClass: ClusterCommentDialogComponent.PANEL_CLASS,
        minWidth: DialogConfig.minWidth,
      });

    return answer;
  }

  onCancelClick(): void {
    this.dialogRef.close(null );
  }

  onOkClick(): void {
    const response: IClusterCommentDialogResponse = {
      comment: this.params.comment
    }
    this.dialogRef.close( response );
  }


  constructor(
    public dialogRef: MatDialogRef<ClusterCommentDialogComponent, IClusterCommentDialogResponse>,
    @Inject(MAT_DIALOG_DATA) params: IClusterCommentDialogParams,
  ) {

    this.params = params;
    this._log.debug( 'this.params', this.params );
  }
}
