
import firebase from 'firebase/compat/app';
import database = firebase.database;
import {IAppProperty, IAppProperty2, AppProperty} from "../../../model/AppProperty";
import {IFirebaseConnection} from "../../FirebaseConnection";


export class FirebaseProperty {

  private static getPath( clientKey:string, propertyId: string ) {
    return `/${clientKey}/properties/${propertyId}`;
  }

  static async readAll(firebaseConnection: IFirebaseConnection, clientKey: string): Promise<AppProperty[]> {
    const properties: AppProperty[] = [];
    const valuesRead: {[propertyKey: string]: IAppProperty;} = await firebaseConnection.database2.getValue( `${clientKey}/properties` );

    if (valuesRead) {
      for (const propertyKey of Object.keys(valuesRead)) {
        properties.push(new AppProperty(valuesRead[propertyKey], propertyKey));
      }
    }

    return properties;
  }


  static async readLegacyReference(fbDb: database.Database, propertyKey: string ): Promise<AppProperty|null> {

    const path = `/hotels/${propertyKey}`;

    const snapshot = await fbDb.ref( path ).once( 'value' );
    const value: IAppProperty = snapshot.val()[0];

    if ( value ) {

      return new AppProperty(value, propertyKey);
    }
    return null;
  }

  /**
   * @deprecated use 'readReferenceRedux' on this class
   */
  static async readReference(fbDb: database.Database, clientKey: string, propertyKey: string ): Promise<AppProperty|null> {

    const path = this.getPath( clientKey, propertyKey );
    const snapshot = await fbDb.ref( path ).once( 'value' );
    const value: IAppProperty = snapshot.val();

    if ( value ) {
      return new AppProperty(value, propertyKey);
    }

    return null;
  }

  static async readReferenceRedux(firebase: IFirebaseConnection, clientKey: string, propertyKey: string): Promise<AppProperty|null> {
    const path = this.getPath(clientKey, propertyKey);
    const value: IAppProperty = await firebase.database2.getValue<IAppProperty>( path );

    if (value) {
      return new AppProperty(value, propertyKey);
    }

    return null;
  }

  static async writeReference(fbDb: database.Database, clientKey: string, property: AppProperty): Promise<void> {
    const path = this.getPath(clientKey, property.propertyKey );
    return fbDb.ref(path).set(property.value);
  }

  static async writeValue(firebaseConnection: IFirebaseConnection, clientKey: string, propertyKey: string, value: IAppProperty): Promise<void> {
    const path = this.getPath(clientKey, propertyKey);
    return firebaseConnection.database2.setValue(path, value);
 }

  static async writeValue2(firebaseConnection: IFirebaseConnection, clientKey: string, propertyKey: string, value: IAppProperty2): Promise<void> {
    const path = this.getPath(clientKey, propertyKey);
    return firebaseConnection.database2.setValue(path, value);
  }
}
