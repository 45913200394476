import {AppEvaluationUser, IAppEvaluationUser} from "../../../model/AppEvaluationUser";
import {IFirebaseConnection} from "../../FirebaseConnection";

export class FirebaseEvaluationUser {

  private static getPath( userUid: string ) {
    return `/users/evaluation/${userUid}`;
  }

  private static ToArray(value: { [p: string]: IAppEvaluationUser }) : AppEvaluationUser[] {
    const appEvaluationUsers: AppEvaluationUser[] = [];
    for( const uuid of Object.keys(value)) {
      appEvaluationUsers.push( AppEvaluationUser.build( value[uuid], uuid ));
    }
    return appEvaluationUsers;
  }

  static async readAllReferences( firebaseConnection: IFirebaseConnection): Promise<AppEvaluationUser[]> {
    const path = `/users/evaluation/`;
    const snapshotValue: { [userUid: string]: IAppEvaluationUser; } = await firebaseConnection.database2.getValue<{ [userUid: string]: IAppEvaluationUser; }>( path );
    return this.ToArray(snapshotValue);
  }

  static async readReference(firebaseConnection: IFirebaseConnection, userUid: string): Promise<AppEvaluationUser|null> {
    const path = FirebaseEvaluationUser.getPath(userUid);
    const snapshotValue = await firebaseConnection.database2.getValue<IAppEvaluationUser>(path);

    if (snapshotValue) {
      return AppEvaluationUser.build(snapshotValue, userUid);
    }
    return null;
  }

  static async writeReference(firebaseConnection: IFirebaseConnection, user: AppEvaluationUser): Promise<void> {
    return FirebaseEvaluationUser.writeValue(firebaseConnection, user.userUid, user.value);
  }

  static async writeValue(firebaseConnection: IFirebaseConnection, userUid: string, value: IAppEvaluationUser): Promise<void> {
    const path = this.getPath(userUid);
    return firebaseConnection.database2.setValue(path, value);
  }
}
