import {ActivatedRoute, Router} from '@angular/router';
import {PageManageUser} from "./page.manage-user/page-manage-user";
import {RouteDescriptor} from "../common/util/RouteDescriptor";

export class BackOfficeRouteManifest {

  static readonly Home = new RouteDescriptor('back-office');

  static readonly Audit = new RouteDescriptor('back-office/audit');

  static readonly Property = new class extends RouteDescriptor {

    async getPropertyKey(route: ActivatedRoute ): Promise<string> {
      return this.getParam( route, 'propertyKey' );
    }

    navigateToPropertyManage(router: Router, propertyKey: string ) {

      const params = {
        propertyKey
      };

      this.navigate( router, params );
    }

    constructor() {
      super('back-office/manage-property/:propertyKey'  );
    }
  }

  static readonly Client = new class extends RouteDescriptor {

    async getClientKey(route: ActivatedRoute ): Promise<string> {
      return this.getParam( route, 'clientKey' );
    }

    navigateToClientManage(router: Router, clientKey: string ) {
      const params = {
        clientKey
      };

      this.navigate( router, params );
    }

    constructor() {
      super('back-office/manage-client/:clientKey'  );
    }
  }

  static readonly User = new class extends RouteDescriptor {

    async getEmail( route: ActivatedRoute ): Promise<string> {
      return  this.getParam( route, 'email' );
    }

    navigateToUserManage(router: Router, email: string ) {
      const params = {
        email
      };

      this.navigate( router, params );
    }

    constructor() {
      super('back-office/manage-user/:email' );
    }
  }

  static readonly Maintenance = new class extends RouteDescriptor {

    async getKey(route: ActivatedRoute ): Promise<string> {
      return this.getParam( route, 'key' );
    }

    navigateToManageMaintenance(router: Router, key: string ) {
      const params = {
        key
      };

      this.navigate( router, params );
    }

    constructor() {
      super('back-office/manage-maintenance/:key'  );
    }
  }

  static readonly Report = new class extends RouteDescriptor {
    async getPropertyKey( route: ActivatedRoute ): Promise<string> {
      return  this.getParam( route, 'propertyKey' );
    }

    navigateToReport(router: Router, propertyKey: string ) {
      const params = {
        propertyKey
      };

      this.navigate( router, params );
    }

    constructor() {
      super('back-office/report/:propertyKey' );
    }
  }
}



