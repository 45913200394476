import {Component, Input } from "@angular/core";

@Component({
  selector: 'label-badge',
  templateUrl: './label-component.html',
  standalone: true,
  styleUrls: ['label-component.scss']
})
export class LabelComponent {
  @Input() position: string;
  @Input() label: string;
}
