import {ILogger} from "../../log/Logger";
import {LoggerFactory} from "../../log/LoggerFactory";
import {AppAnswer} from "../AppAnswer";
import {AppCluster} from "../app.cluster/AppCluster";

export class SubsectionScoreCalculator {
  private static _log: ILogger = LoggerFactory.build( 'SubsectionScoreCalculator' );

  public calculate(appCluster: AppCluster, appAnswers: AppAnswer[]) : number | null {
    let numerator:number = null;
    let denominator:number = 0;

    for (let appAnswer of appAnswers) {
      if (appAnswer.value.needsAnswer && appAnswer.value.score != null) {
        if (numerator == null) {
          numerator = 0;
        }
        const weight = appCluster?.value?.weight ?? 1;
        numerator += appAnswer.value.score * weight;
        denominator += weight;
      }
    }

    let score = (numerator == null) ? null : numerator / Math.max(denominator, 1);
    if (appCluster.value.essential && score == null) {
      score = 0;
    }

    SubsectionScoreCalculator._log.info("Score for ", "subsection", appCluster.value.name, "essential",
      appCluster.value.essential, "score", score, "id", appCluster.value.clusterTypeId);
    return score;
  }
}
